header {
	z-index: 10;
	position: relative;
	background-color: @white;
	box-shadow: 0 3px 3px @gray60;
	.header-top {
		padding: 10px;
		display: block;
		.logo-wrapper {
		}
		.loggedin-user {
			text-align: center;
			span {
				margin: 3px 0;
				display: block;
				max-width: 100px;
				overflow: hidden;
				white-space: nowrap;
				text-overflow: ellipsis;
				&:before {
					color: @gray29;
					font-size: 18px;
					margin: 0 5px 5px 0;
					.font-icon('\f2be', '5px');
				}
			}
			.topmenu {
				cursor: default;
				padding: 7px 15px;
				position: relative;
				border-radius: 5px 5px 0 0;
				border: 1px solid transparent;
				ul.submenu {
					top: 100%;
					margin: 0;
					padding: 0;
					z-index: 1;
					right: -1px;
					width: 200px;
					display: none;
					overflow: hidden;
					list-style: none;
					position: absolute;
					background-color: @white;
					border-radius: 5px 0 5px 5px;
					border: 1px solid @gainsboro;
					box-shadow: -1px 3px 3px @gray73;
					li {
						margin: 0;
						a {
							display: block;
							padding: 12px 10px;
							box-sizing: border-box;
							border-top: 1px solid @gainsboro;
							&:hover, &:focus {
								color: @white;
								background-color: @dodgerblue;
							}
						}
						&:first-child {
							a {
								border-top: none;
							}
						}
					}
				}
				&:hover, &:focus {
					border-color: @gainsboro;
					background-color: @white-smoke;
					.submenu {
						display: block;
					}
				}
			}
		}
	}
}

.app-display {
	header {
		height: @headerHeight;
		box-sizing: border-box;
		padding: 10px 0;
		box-shadow: 0 0 15px @gainsboro;
		box-shadow: 0 0 15px fade(@black, 10%);
	}
}

/**
 * small tablet
 */
@media only screen and (min-width : @sm-tablet-width) {
	header {
		.header-top {
			.loggedin-user {
				span {
					display: inline;
					margin: 3px 0;
					&:before {
						font-size: 25px;
					}
				}
			}
		}
	}
}
