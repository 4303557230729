@nav-color: @offwhite;

nav {
	&.mainnav {
		overflow: auto;
		font-size: 13px;
		margin: 10px 0 0;
		background-color: @nav-color;
		border-top: 1px solid darken(@nav-color, 2%);
		a {
			float: left;
			color: @gray29;
			overflow: hidden;
			position: relative;
			padding: 10px 12px;
			text-decoration: none;
			transition: all .25s linear;
			&:after {
				bottom: 0;
				content: '';
				left: 0;
				height: 4px;
				width: 100%;
				display: block;
				position: absolute;
				background-color: @dodgerblue;
				transform: translateX(-100%);
				transition: all .5s ease-in-out;
			}
			&:hover, &:focus, &.current {
				&:after {
					transform: translateX(0%);
				}
			}
		}
	}
}

.app-display {
	.sidebar {
		nav {
			&.mainnav {
				background-color: transparent;
				box-sizing: border-box;
				border: none;
				height: calc(~'100% - @{sidebarCloser}');
				font-size: 16px;
				margin: 0;
				padding: 10px 0;
				overflow-x: hidden;
				overflow-y: auto;
				a {
					color: @white;
					display: block;
					float: none;
					overflow: hidden;
					padding: 18px 20px;
					white-space: nowrap;
					span {
						white-space: nowrap;
						text-overflow: clip;
					}
					&.dashboard:before { .font-icon('\f0e4', '15px'); font-size: 20px; }
					&.order:before { .font-icon('\f15b', '15px'); font-size: 20px; }
					&.user:before { .font-icon('\f007', '15px'); font-size: 20px; }
					&.admin:before { .font-icon('\f023', '15px'); font-size: 20px; }
					&:after {
						height: 100%;
						width: 4px;
						transform: translateY(-100%);
					}
					&:hover, &:focus, &.current {
						background-color: darken(@darknavy, 6%);
						&:after {
							transform: translateY(0%);
						}
					}
				}
			}
		}
		&.collapsed {
			nav {
				&.mainnav {
					a {
						span {
							opacity: 0;
						}
					}
				}
			}
		}
	}
}

/**
 * small tablet
 */
@media only screen and (min-width : @sm-tablet-width) {
	nav {
		&.mainnav {
			font-size: 16px;

			a {
				padding: 14px 20px;
			}
		}
	}
	.app-display {
		nav {
			&.mainnav {
				a {
					padding: 18px 20px;
				}
			}
		}
	}
}
