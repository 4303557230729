.order-list {
	.tbl-row {
		overflow: hidden;
		.tbl-col {
			font-size: 14px;
			&.preview-img {
				float: left;
				margin: 0 10px 10px 0;
			}
			&.approved {
				color: darken(@frontend_approvegreen, 10%);
				&::before {
					.font-icon('\f058');
				}
			}
			&.rejected,
			&.revision {
				color: darken(@frontend_rejectionred, 10%);
				&::before {
					.font-icon('\f071');
				}
			}
		}
	}
}

@media only screen and (min-width : @tablet-width) {
	.order-list {
		.tbl-row {
			.tbl-col {
				font-size: 16px;
				&.preview-img {
					float: none;
					margin: 0;
				}
			}
		}
	}
}
