// Global Admin Variables
@headerHeight: 80px;
@sidebarCloser: 50px;
@sidebarExpanded: 180px;
@sidebarCollapsed: 60px;

body {
	background-color: lighten(@darknavy, 10%);
}

h1 {
	margin-bottom: 15px;
	padding-bottom: 15px;
	border-bottom: 1px solid @gray83;
}

.wrapper {
	height: auto;
	min-height: 400px;
	position: relative;
	box-sizing: border-box;
	// padding: 10px 10px 15px;
	padding: 10px 25px 15px;
	background-color: @div-wrapper-color;
}

.app-display {
	box-sizing: border-box;
	height: 100vh;
	overflow: hidden;
	width: 100%;
	min-width: 600px;
	.sidebar {
		background-color: @nav-dark-color;
		box-sizing: border-box;
		color: @white;
		height: calc(~'100% - @{headerHeight}');
		left: 0;
		margin: 0;
		padding: 20px 0;
		position: absolute;
		width: @sidebarExpanded;
		transition: .3s width ease-in-out;
		z-index: 10;
		.collapse-btn-wrapper {
			border-top: 1px solid darken(@nav-dark-color, 10%);
			.collapse-btn {
				float: none;
				clear: both;
				display: block;
				padding: 18px 20px;
				span {
					border-radius: 100%;
					background-color: @nav-dark-color;
					color: @white;
					cursor: pointer;
					float: right;
					height: 15px;
					padding: 10px;
					transition: .25s background-color linear, .25s color linear;
					width: 15px;
					&:before {
						.font-icon('\f053');
					}
					&:hover {
						background-color: @white;
						color: @nav-dark-color;
					}

				}
			}

		}
		&.collapsed {
			width: @sidebarCollapsed;
			+ .main-section {
				padding-left: @sidebarCollapsed;
			}
			.collapse-btn {
				span:before {
					.font-icon('\f054', '3px', 'left');
				}
			}
		}
	}
	.main-section {
		box-sizing: border-box;
		overflow: auto;
		padding-left: @sidebarExpanded;
		width: 100%;
		height: calc(~'100% - @{headerHeight}');
		transition: .3s padding ease-in-out;
	}
}
