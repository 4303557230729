.dashboard-widgets {
	.widget-row {
		display: block;
		margin: 15px 0;
		border-top: 1px solid @silver;
		&:first-child {
			border-top: 0;
		}
		&.widgets {
			display: flex;
			flex-flow: column;
			justify-content: space-between;
		}
		.widget {
			box-sizing: border-box;
			margin: 10px 0;
			h3 {
				margin: 5px 0 15px;
			}
			.widget-content {
				padding: 15px 0;
			}
			&.mini {
				background-color: @white;
				box-shadow: 0 0 20px fade(@black, 20%);
				border-radius: 5px;
				box-sizing: border-box;
				display: block;
				overflow: hidden;
				width: 100%;
				h3 {
					background-color: @darknavy;
					box-sizing: border-box;
					border-radius: 5px 5px 0 0;
					color: @white;
					margin: 0;
					padding: 10px;
				}
				.widget-content {
					box-sizing: border-box;
					padding: 10px;
				}
				.needs-revision,
				.awaiting-decision {
					.status-title {
						border-bottom-width: 2px;
						border-bottom-style: solid;
						.status-title-block {
							color: @white;
							display: inline-block;
							font-weight: bold;
							padding: 5px;
						}
					}
					.status-orders {
						color: @gray23;
						font-size: 0.7em;
						font-weight: normal;
						padding: 5px;
						max-height: 150px;
						overflow-x: hidden;
						overflow-y: auto;
						.order-link {
							font-weight: bold;
							font-size: 14px;
							text-overflow: ellipsis;
							white-space: nowrap;
						}
						ul {
							margin: 5px;
							padding-left: 10px;
						}
					}
				}
				.needs-revision {
					.status-title {
						border-bottom-color: @frontend_rejectionred;
						.status-title-block {
							background-color: @frontend_rejectionred;
						}
					}
					ul, a {
						color: @frontend_rejectionred;
					}
				}
				.awaiting-decision {
					.status-title {
						border-bottom-color: @dodgerblue;
						.status-title-block {
							background-color: @dodgerblue;
						}
					}
					ul, a {
						color: @dodgerblue;
					}
				}
			}
		}

	}
	.wfo_widget {
		overflow: hidden;
		&.wfo_number {
			display: inline-block;
			min-width: 90px;
			padding: 5px;
			width: auto;
		}
		&.wfo_graph.pie {
			float: left;
			height: auto !important;
			max-width: 280px;
			width: 100% !important;
			canvas {
				height: auto !important;
				max-width: 280px;
				width: 100% !important;
			}
		}
	}
}

/**
 * small tablet
 */
 @media only screen and (min-width : @tablet-width) {
	.dashboard-widgets {
		.widget-row {
			&.widgets {
				flex-flow: row nowrap;
			}
			.widget {
				&.mini {
					display: inline-block;
					vertical-align: top;
					width: 32%;
				}
			}
		}
	}
 }
