/**
 * colors
 */
/**
 * Main components to be used
 */
/**
 * Device sizes
 */
/**
 * Defaults for tags
 */
* {
  word-wrap: break-word;
}
body {
  font-size: 16px;
  line-height: normal;
  font-family: 'Roboto', Helvetica, Arial, sans-serif;
}
a {
  color: #4876ff;
  text-decoration: underline;
}
a:hover,
a:focus {
  text-decoration: none;
}
.clearfix {
  clear: both;
  float: none;
  display: block;
}
.text-hide {
  float: left;
  display: block;
  text-indent: -9999px;
}
.vmid {
  vertical-align: middle;
}
.hidden {
  display: none;
}
.site-width {
  width: 98%;
  max-width: 1200px;
}
.mobile,
.mobile-tablet {
  display: block !important;
}
.mobile-tbl,
.mobile-tablet-tbl {
  display: table-cell !important;
}
.tablet,
.desktop,
.tablet-tbl,
.desktop-tbl,
.desktop-tablet,
.desktop-tablet-tbl {
  display: none !important;
}
.lfloat,
.rfloat {
  width: 100%;
}
.p-lfloat {
  float: left;
}
.p-rfloat {
  float: right;
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  .tablet,
  .mobile-tablet,
  .desktop-tablet {
    display: block !important;
  }
  .tablet-tbl,
  .mobile-tablet-tbl,
  .desktop-tablet-tbl {
    display: table-cell !important;
  }
  .mobile,
  .desktop,
  .mobile-tbl,
  .desktop-tbl {
    display: none !important;
  }
  .lfloat {
    width: auto;
    float: left;
  }
  .rfloat {
    width: auto;
    float: right;
  }
}
/**
 * small desktop
 */
@media only screen and (min-width: 961px) {
  .desktop,
  .desktop-tablet {
    display: block !important;
  }
  .desktop-tbl .desktop-tablet-tbl {
    display: table-cell !important;
  }
  .mobile,
  .tablet,
  .mobile-tbl,
  .tablet-tbl,
  .mobile-tablet,
  .mobile-tablet-tbl {
    display: none !important;
  }
}
.col {
  display: inline-block;
}
.col-full,
.col-span1,
.col-span2,
.col-span3,
.col-span4,
.col-span5,
.col-span6,
.col-span7,
.col-span8,
.col-span9,
.col-span10,
.col-span11,
.col-span12 {
  width: 100%;
}
.col-pmspan1 {
  width: calc((97.4/12)*1*1%) !important;
}
.col-pmspan2 {
  width: calc((97.4/12)*2*1%) !important;
}
.col-pmspan3 {
  width: calc((97.4/12)*3*1%) !important;
}
.col-pmspan4 {
  width: calc((97.4/12)*4*1%) !important;
}
.col-pmspan5 {
  width: calc((97.4/12)*5*1%) !important;
}
.col-pmspan6 {
  width: calc((97.4/12)*6*1%) !important;
}
.col-pmspan7 {
  width: calc((97.4/12)*7*1%) !important;
}
.col-pmspan8 {
  width: calc((97.4/12)*8*1%) !important;
}
.col-pmspan9 {
  width: calc((97.4/12)*9*1%) !important;
}
.col-pmspan10 {
  width: calc((97.4/12)*10*1%) !important;
}
.col-pmspan11 {
  width: calc((97.4/12)*11*1%) !important;
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  .col-span1,
  .col-pmspan1 {
    width: calc((97.4/12)*1*1%) !important;
  }
  .col-span2,
  .col-pmspan2 {
    width: calc((97.4/12)*2*1%) !important;
  }
  .col-span3,
  .col-pmspan3 {
    width: calc((97.4/12)*3*1%) !important;
  }
  .col-span4,
  .col-pmspan4 {
    width: calc((97.4/12)*4*1%) !important;
  }
  .col-span5,
  .col-pmspan5 {
    width: calc((97.4/12)*5*1%) !important;
  }
  .col-span6,
  .col-pmspan6 {
    width: calc((97.4/12)*6*1%) !important;
  }
  .col-span7,
  .col-pmspan7 {
    width: calc((97.4/12)*7*1%) !important;
  }
  .col-span8,
  .col-pmspan8 {
    width: calc((97.4/12)*8*1%) !important;
  }
  .col-span9,
  .col-pmspan9 {
    width: calc((97.4/12)*9*1%) !important;
  }
  .col-span10,
  .col-pmspan10 {
    width: calc((97.4/12)*10*1%) !important;
  }
  .col-span11,
  .col-pmspan11 {
    width: calc((97.4/12)*11*1%) !important;
  }
}
.accord-wrapper .accord-action {
  color: inherit;
  display: block;
  position: relative;
  text-decoration: none;
  box-sizing: border-box;
}
.accord-wrapper .accord-action:after {
  top: 0;
  right: 0;
  position: absolute;
  content: '\f067';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
.accord-wrapper .accord-action.active:after {
  content: '\f068';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
.accord-wrapper .accord-content {
  display: none;
}
.lb-blocker {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  filter: "alpha(opacity=50)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  opacity: 0.5;
  z-index: 1000;
  display: none;
  position: fixed;
  background-color: #000000;
}
.lb-wrapper .lb-opener > * {
  cursor: pointer;
}
.lb-wrapper .lb-body-wrapper {
  width: auto;
  height: auto;
  display: none;
  z-index: 1001;
  overflow: auto;
  max-width: 95%;
  max-height: 90%;
  position: fixed;
  background-color: #ffffff;
  border: 1px solid #4a4a4a;
  box-shadow: 0 0 15px 2px #4a4a4a;
}
.csstransforms .lb-wrapper .lb-body-wrapper {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.no-csstransforms .lb-wrapper .lb-body-wrapper {
  top: 50%;
  left: 50%;
  margin: 0;
  margin-top: -200px;
  margin-left: -250px;
}
.lb-wrapper .lb-body-wrapper .lb-close-bar {
  padding: 10px 15px 0 0 ;
  text-align: right;
}
.lb-wrapper .lb-body-wrapper .lb-close-bar i {
  cursor: pointer;
}
.lb-wrapper .lb-body-wrapper .lb-body {
  width: 100%;
  height: 100%;
  padding: 15px;
  display: block;
  box-sizing: border-box;
}
.pagination-bar {
  margin: 10px 0;
}
.pagination-bar:after {
  content: '';
  clear: both;
  float: none;
  display: block;
}
.pagination-bar .pagination-info {
  padding: 5px;
  font-size: 14px;
}
.pagination-bar .pagination-info span {
  padding: 0 10px;
}
.pagination-bar .pagination-info span:first-child {
  padding-left: 0;
}
.pagination-bar .pagination-info span:last-child {
  padding-right: 0;
}
.pagination-bar .next-page,
.pagination-bar .prev-page,
.pagination-bar .page-number {
  float: left;
  color: #4a4a4a;
  overflow: hidden;
  padding: 4px 14px;
  text-decoration: none;
  background-color: #ededed;
  transition: all 0.2s linear;
  border: 1px solid #c7c7c7;
}
.pagination-bar .next-page:hover,
.pagination-bar .prev-page:hover,
.pagination-bar .page-number:hover,
.pagination-bar .next-page:focus,
.pagination-bar .prev-page:focus,
.pagination-bar .page-number:focus {
  transform: scale(1.05);
  background-color: #fafafa;
}
.pagination-bar .next-page:active,
.pagination-bar .prev-page:active,
.pagination-bar .page-number:active {
  background-color: #e0e0e0;
}
.pagination-bar .next-page.disabled,
.pagination-bar .prev-page.disabled,
.pagination-bar .page-number.disabled {
  cursor: default;
  color: #bababa;
}
.pagination-bar .next-page.disabled:hover,
.pagination-bar .prev-page.disabled:hover,
.pagination-bar .page-number.disabled:hover,
.pagination-bar .next-page.disabled:focus,
.pagination-bar .prev-page.disabled:focus,
.pagination-bar .page-number.disabled:focus {
  transform: none;
  background-color: #ededed;
}
.pagination-bar .next-page.current,
.pagination-bar .prev-page.current,
.pagination-bar .page-number.current {
  color: #ffffff;
  font-weight: bold;
  background-color: #1e90ff;
  border-color: #006ad1;
}
.pagination-bar .next-page.current:hover,
.pagination-bar .prev-page.current:hover,
.pagination-bar .page-number.current:hover,
.pagination-bar .next-page.current:focus,
.pagination-bar .prev-page.current:focus,
.pagination-bar .page-number.current:focus {
  transform: none;
  background-color: #1e90ff;
}
.pagination-bar .next-page {
  border-radius: 0 5px 5px 0;
}
.pagination-bar .prev-page {
  border-radius: 5px 0 0 5px;
}
.autocomplete-menu-wrapper {
  left: 0;
  top: 98%;
  z-index: 1;
  width: 100%;
  padding: 0 4px;
  position: absolute;
  box-sizing: border-box;
}
.autocomplete-menu-wrapper .autocomplete-menu {
  margin: 0;
  padding: 0;
  width: 100%;
  height: auto;
  overflow: auto;
  list-style: none;
  max-height: 300px;
  overflow-x: hidden;
  background-color: #ffffff;
  box-shadow: -1px 5px 5px #999999;
  border: 1px solid #a9a9a9;
}
.autocomplete-menu-wrapper .autocomplete-menu .autocomplete-item {
  margin: 0;
  padding: 8px;
  display: block;
  cursor: pointer;
  box-sizing: border-box;
  transition: all 0.25s linear;
  border: 1px solid transparent;
}
.autocomplete-menu-wrapper .autocomplete-menu .autocomplete-item:hover,
.autocomplete-menu-wrapper .autocomplete-menu .autocomplete-item:focus,
.autocomplete-menu-wrapper .autocomplete-menu .autocomplete-item.highlight {
  background-color: #84c2ff;
  border-color: #1e90ff;
}
/**
 * Div Table
 */
.perm-tbl-div {
  width: 100%;
  display: table;
}
.perm-tbl-div .tbl-row {
  display: table-row;
  padding: 0;
}
.perm-tbl-div .tbl-row .tbl-col {
  display: table-cell;
  vertical-align: middle;
  padding: 5px;
}
.perm-tbl-div .tbl-row .tbl-head {
  font-weight: bold;
  display: table-cell;
}
.tbl-div {
  display: table;
}
.tbl-div .first {
  padding-left: 20px;
}
.tbl-div .last {
  padding-right: 20px;
}
.tbl-div .tbl-row {
  padding: 10px;
  display: table-row;
  border-bottom: 1px solid #868686;
}
.tbl-div .tbl-row:first-child {
  padding: 0;
}
.tbl-div .tbl-row.even {
  background-color: #ececec;
}
.tbl-div .tbl-row.odd {
  background-color: #dbdbdb;
}
.tbl-div .tbl-row.search-and-filters.hidden {
  display: none;
}
.tbl-div .tbl-row .tbl-col {
  display: table-cell;
  vertical-align: middle;
  padding: 4px;
}
.tbl-div .tbl-row .tbl-search {
  padding: 5px;
  display: table-cell;
  vertical-align: middle;
  background-color: #222b3a;
}
.tbl-div .tbl-row .toggle-searchbar {
  cursor: pointer;
}
.tbl-div .tbl-row .toggle-searchbar:after {
  content: '\f0b0';
  font-family: 'FontAwesome';
  margin-right: 0;
}
.tbl-div .tbl-row .tbl-head {
  display: none;
  font-weight: bold;
  vertical-align: middle;
}
.tbl-div .tbl-row .tbl-head[data-attr] {
  cursor: pointer;
}
.tbl-div .tbl-row .tbl-head[data-attr]:after {
  content: '\f0dc';
  font-family: 'FontAwesome';
  margin-left: 5px;
}
.tbl-div .tbl-row .tbl-head.asc:after {
  content: '\f0de';
  font-family: 'FontAwesome';
  margin-left: 5px;
}
.tbl-div .tbl-row .tbl-head.desc:after {
  content: '\f0dd';
  font-family: 'FontAwesome';
  margin-left: 5px;
}
.data-tbl .tbl-row {
  transition: background-color 0.25s linear;
}
.data-tbl .tbl-row .tbl-col {
  transition: background-color 0.25s linear;
}
/**
 * small tablet
 */
@media only screen and (min-width: 601px) {
  .tbl-div.data-tbl .tbl-row:hover,
  .tbl-div.data-tbl .tbl-row:focus {
    cursor: default;
  }
  .tbl-div.data-tbl .tbl-row[data-row-action]:hover,
  .tbl-div.data-tbl .tbl-row[data-row-action]:focus {
    cursor: pointer;
    background-color: #bde0ff;
  }
  .tbl-div.data-tbl .tbl-row[data-row-action]:hover .tbl-col,
  .tbl-div.data-tbl .tbl-row[data-row-action]:focus .tbl-col {
    border-bottom-color: #57b1ff;
  }
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  .tbl-div {
    width: 100%;
    display: table;
    border-collapse: collapse;
  }
  .tbl-div .tbl-row {
    padding: 0;
    display: table-row;
    border-bottom: none;
  }
  .tbl-div .tbl-row .tbl-col {
    padding: 5px;
    display: table-cell;
    border-bottom: 1px solid #868686;
  }
  .tbl-div .tbl-row .tbl-head {
    padding: 5px;
    color: #ffffff;
    display: table-cell;
    background-color: #222b3a;
  }
}
.action-bar {
  padding: 5px;
  display: block;
  overflow: hidden;
  margin: 15px 0;
  box-sizing: border-box;
  background-color: #ededed;
  border: 1px solid #d4d4d4;
}
.action-bar.col {
  display: inline-block;
}
.btn-field {
  display: block;
  margin: 15px 0;
  overflow: hidden;
}
.btn-field.col {
  display: inline-block;
}
.btn {
  height: auto;
  outline: none;
  display: block;
  font-size: 20px;
  font-weight: bold;
  border-radius: 5px;
  padding: 10px 20px;
  text-align: center;
  text-decoration: none;
  box-sizing: border-box;
  transition: all 0.2s linear;
}
.btn.small {
  height: auto;
  font-size: 16px;
  padding: 5px 10px;
}
.btn.lfloat,
.btn.rfloat {
  margin: 6px 0;
}
.btn.submit,
.btn[type="submit"] {
  color: #ffffff;
  background-color: #ffa500;
  border: 1px solid #cc8400;
  text-shadow: 1px 1px 1px #cc8400;
}
.btn.submit:hover,
.btn[type="submit"]:hover,
.btn.submit:focus,
.btn[type="submit"]:focus {
  background-color: #ffb733;
}
.btn.submit:active,
.btn[type="submit"]:active {
  background-color: #e69500;
}
.btn.secondary {
  color: #ffffff;
  background-color: #4682b4;
  border: 1px solid #38678f;
  text-shadow: 1px 1px 1px #38678f;
}
.btn.secondary:hover,
.btn.secondary:focus {
  background-color: #699bc4;
}
.btn.secondary:active {
  background-color: #3f75a2;
}
.btn.additional {
  color: #4a4a4a;
  background-color: #d4d4d4;
  border: 1px solid #bbbbbb;
  text-shadow: none;
}
.btn.additional:hover,
.btn.additional:focus {
  background-color: #eeeeee;
}
.btn.additional:active {
  background-color: #c7c7c7;
}
.btn.delete {
  color: #ffffff;
  background-color: #b22222;
  border: 1px solid #871a1a;
  text-shadow: 1px 1px 1px #871a1a;
}
.btn.delete:hover,
.btn.delete:focus {
  background-color: #d73030;
}
.btn.delete:active {
  background-color: #9d1e1e;
}
.btn.approval {
  color: #ffffff;
  background-color: #228b22;
  border: 1px solid #186218;
  text-shadow: 1px 1px 1px #186218;
}
.btn.approval:hover,
.btn.approval:focus {
  background-color: #2cb42c;
}
.btn.approval:active {
  background-color: #1d771d;
}
.btn.disabled,
.btn[disabled] {
  background-color: #d3d3d3 !important;
  border: 1px solid #bababa !important;
  text-shadow: 1px 1px 1px #bababa !important;
  cursor: not-allowed;
}
.btn.disabled:hover,
.btn[disabled]:hover,
.btn.disabled:focus,
.btn[disabled]:focus {
  background-color: #ececec !important;
}
.btn.disabled:active,
.btn[disabled]:active {
  background-color: #c6c6c6 !important;
}
.btn.disabled:active,
.btn[disabled]:active,
.btn.disabled:focus,
.btn[disabled]:focus,
.btn.disabled:hover,
.btn[disabled]:hover {
  background-color: #d3d3d3 !important;
}
.btn .btn-icon {
  margin-right: 3px;
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  .btn.lfloat,
  .btn.rfloat {
    margin: 5px;
  }
}
form {
  margin: auto;
  max-width: 1200px;
}
form:after {
  content: '';
  clear: both;
  float: none;
  display: block;
}
:-moz-placeholder {
  color: #c0c0c0;
}
::-moz-placeholder {
  color: #c0c0c0;
}
:-ms-input-placeholder {
  color: #c0c0c0;
}
::-webkit-input-placeholder {
  color: #c0c0c0;
}
.field {
  margin: 10px 0;
  display: block;
  position: relative;
  box-sizing: border-box;
}
.field.col {
  padding: 0 4px;
  display: inline-block;
}
.field.split {
  width: 50%;
  margin: 5px;
  display: inline-block;
}
.field.thirds {
  margin: 5px;
  width: 33.33%;
  display: inline-block;
}
.field.quarters {
  width: 25%;
  margin: 5px;
  display: inline-block;
}
.field input[type="url"],
.field input[type="date"],
.field input[type="text"],
.field input[type="email"],
.field input[type="phone"],
.field input[type="search"],
.field input[type="number"],
.field input[type="password"] {
  height: 40px;
  outline: 0;
  width: 100%;
  padding: 6px;
  outline: none;
  border-radius: 3px;
  box-sizing: border-box;
  transition: all 0.2s linear;
  border: 1px solid #a9a9a9;
}
.field input[type="url"]:hover,
.field input[type="date"]:hover,
.field input[type="text"]:hover,
.field input[type="email"]:hover,
.field input[type="phone"]:hover,
.field input[type="search"]:hover,
.field input[type="number"]:hover,
.field input[type="password"]:hover {
  border-color: #838383;
  box-shadow: inset 0 0 2px #b6b6b6;
}
.field input[type="url"]:focus,
.field input[type="date"]:focus,
.field input[type="text"]:focus,
.field input[type="email"]:focus,
.field input[type="phone"]:focus,
.field input[type="search"]:focus,
.field input[type="number"]:focus,
.field input[type="password"]:focus,
.field input[type="url"]:active,
.field input[type="date"]:active,
.field input[type="text"]:active,
.field input[type="email"]:active,
.field input[type="phone"]:active,
.field input[type="search"]:active,
.field input[type="number"]:active,
.field input[type="password"]:active {
  border: 1px solid #0077ea;
  box-shadow: inset 0 0 2px #0077ea;
}
.field input[type="url"][readonly],
.field input[type="date"][readonly],
.field input[type="text"][readonly],
.field input[type="email"][readonly],
.field input[type="phone"][readonly],
.field input[type="search"][readonly],
.field input[type="number"][readonly],
.field input[type="password"][readonly] {
  border: 1px solid #d4d4d4;
  background-color: #ededed;
}
.field input[type="url"][readonly]:hover,
.field input[type="date"][readonly]:hover,
.field input[type="text"][readonly]:hover,
.field input[type="email"][readonly]:hover,
.field input[type="phone"][readonly]:hover,
.field input[type="search"][readonly]:hover,
.field input[type="number"][readonly]:hover,
.field input[type="password"][readonly]:hover,
.field input[type="url"][readonly]:focus,
.field input[type="date"][readonly]:focus,
.field input[type="text"][readonly]:focus,
.field input[type="email"][readonly]:focus,
.field input[type="phone"][readonly]:focus,
.field input[type="search"][readonly]:focus,
.field input[type="number"][readonly]:focus,
.field input[type="password"][readonly]:focus,
.field input[type="url"][readonly]:active,
.field input[type="date"][readonly]:active,
.field input[type="text"][readonly]:active,
.field input[type="email"][readonly]:active,
.field input[type="phone"][readonly]:active,
.field input[type="search"][readonly]:active,
.field input[type="number"][readonly]:active,
.field input[type="password"][readonly]:active {
  outline: none;
  box-shadow: none;
  background-color: #ededed;
}
.field input[type="url"].sm,
.field input[type="date"].sm,
.field input[type="text"].sm,
.field input[type="email"].sm,
.field input[type="phone"].sm,
.field input[type="search"].sm,
.field input[type="number"].sm,
.field input[type="password"].sm {
  height: 30px;
  padding: 3px 6px;
}
.field input[type="date"] {
  display: inline-block;
  font-size: 11px;
  width: 115px !important;
}
.field textarea {
  height: auto;
  outline: 0;
  width: 100%;
  padding: 6px;
  outline: none;
  border-radius: 3px;
  box-sizing: border-box;
  transition: all 0.2s linear;
  border: 1px solid #a9a9a9;
}
.field textarea:hover {
  border-color: #838383;
  box-shadow: inset 0 0 2px #b6b6b6;
}
.field textarea:focus,
.field textarea:active {
  border: 1px solid #0077ea;
  box-shadow: inset 0 0 2px #0077ea;
}
.field textarea[readonly="true"] {
  border: 1px solid #d4d4d4;
  background-color: #ededed;
}
.field textarea[readonly="true"]:hover,
.field textarea[readonly="true"]:focus,
.field textarea[readonly="true"]:active {
  outline: none;
  box-shadow: none;
  background-color: #ededed;
}
.field textarea[data-gramm] {
  transition: all 0.2s linear !important;
}
.field .select-wrapper {
  position: relative;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.field .select-wrapper select {
  outline: 0;
  width: 100%;
  padding: 6px;
  outline: none;
  border-radius: 3px;
  box-sizing: border-box;
  transition: all 0.2s linear;
  border: 1px solid #a9a9a9;
  height: 40px;
  appearance: none;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  background-color: #ffffff;
  padding: 6px 24px 6px 6px;
  vertical-align: middle;
}
.field .select-wrapper select:hover {
  border-color: #838383;
  box-shadow: inset 0 0 2px #b6b6b6;
}
.field .select-wrapper select:focus,
.field .select-wrapper select:active {
  border: 1px solid #0077ea;
  box-shadow: inset 0 0 2px #0077ea;
}
.field .select-wrapper select option {
  white-space: nowrap;
  text-overflow: ellipsis;
}
.field .select-wrapper select::-ms-expand {
  display: none;
}
.field .select-wrapper:after {
  top: 12px;
  z-index: 9;
  right: 10px;
  filter: "alpha(opacity=50)";
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=50)";
  opacity: 0.5;
  width: auto;
  height: auto;
  font-size: 18px;
  position: absolute;
  content: '\f0d7';
  font-family: 'FontAwesome';
  margin-right: 2px;
  pointer-events: none;
}
.ie9 .field .select-wrapper:before,
.ie8 .field .select-wrapper:before,
.ie7 .field .select-wrapper:before,
.lt-ie7 .field .select-wrapper:before {
  top: 2px;
  z-index: 7;
  right: 3px;
  width: 45px;
  content: '';
  height: 35px;
  position: absolute;
  background: #ffffff;
  pointer-events: none;
}
.field .select-wrapper.sm select {
  height: 30px;
  padding: 3px 24px 3px 6px;
}
.field .select-wrapper.sm:after {
  top: 6px;
}
.ie9 .field .select-wrapper.sm:before,
.ie8 .field .select-wrapper.sm:before,
.ie7 .field .select-wrapper.sm:before,
.lt-ie7 .field .select-wrapper.sm:before {
  height: 25px;
}
.field.checkbox-holder input[type='checkbox'].hidden + label.checkbox {
  cursor: pointer;
}
.field.checkbox-holder input[type='checkbox'].hidden + label.checkbox.right {
  text-align: right;
}
.field.checkbox-holder input[type='checkbox'].hidden + label.checkbox:before {
  color: #808080;
  font-size: 24px;
  cursor: pointer;
  vertical-align: middle;
  content: '\f096';
  font-family: 'FontAwesome';
  margin-right: 15px;
}
.field.checkbox-holder input[type='checkbox'].hidden + label.checkbox:before:hover {
  color: #000000;
}
.field.checkbox-holder input[type='checkbox'].hidden:checked + label.checkbox.right:before {
  right: -3.5px;
  content: '\f046';
  font-family: 'FontAwesome';
  margin-right: 15px;
}
.field.checkbox-holder input[type='checkbox'].hidden:checked + label.checkbox:before {
  margin-left: 1px;
  position: relative;
  color: #228b22;
  content: '\f046';
  font-family: 'FontAwesome';
  margin-right: 10.5px;
}
label {
  margin: 5px 0;
  display: inline-block;
}
label.required:before {
  margin: 0 2px;
  font-size: 8px;
  color: #b22222;
  content: '\f069';
  font-family: 'FontAwesome';
  margin-right: 2px;
  vertical-align: super;
}
.form-field-styling {
  outline: 0;
  width: 100%;
  padding: 6px;
  outline: none;
  border-radius: 3px;
  box-sizing: border-box;
  transition: all 0.2s linear;
  border: 1px solid #a9a9a9;
}
.form-field-styling:hover {
  border-color: #838383;
  box-shadow: inset 0 0 2px #b6b6b6;
}
.form-field-styling:focus,
.form-field-styling:active {
  border: 1px solid #0077ea;
  box-shadow: inset 0 0 2px #0077ea;
}
.form-field-disabled-styling {
  border: 1px solid #d4d4d4;
  background-color: #ededed;
}
.form-field-disabled-styling:hover,
.form-field-disabled-styling:focus,
.form-field-disabled-styling:active {
  outline: none;
  box-shadow: none;
  background-color: #ededed;
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  input[type="submit"] {
    width: auto;
    float: right;
  }
}
.field.input-error .error-message {
  padding: 5px;
  border-width: 1;
  border-radius: 3px;
  border-style: solid;
  box-sizing: border-box;
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
}
.field.input-error input[type="url"],
.field.input-error input[type="text"],
.field.input-error input[type="email"],
.field.input-error input[type="phone"],
.field.input-error input[type="search"],
.field.input-error input[type="number"],
.field.input-error input[type="password"] {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
}
.field.input-error input[type="url"]:hover,
.field.input-error input[type="text"]:hover,
.field.input-error input[type="email"]:hover,
.field.input-error input[type="phone"]:hover,
.field.input-error input[type="search"]:hover,
.field.input-error input[type="number"]:hover,
.field.input-error input[type="password"]:hover {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
  box-shadow: inset 0 0 2px #ff7474;
}
.field.input-error input[type="url"]:active,
.field.input-error input[type="text"]:active,
.field.input-error input[type="email"]:active,
.field.input-error input[type="phone"]:active,
.field.input-error input[type="search"]:active,
.field.input-error input[type="number"]:active,
.field.input-error input[type="password"]:active,
.field.input-error input[type="url"]:focus,
.field.input-error input[type="text"]:focus,
.field.input-error input[type="email"]:focus,
.field.input-error input[type="phone"]:focus,
.field.input-error input[type="search"]:focus,
.field.input-error input[type="number"]:focus,
.field.input-error input[type="password"]:focus {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
  box-shadow: inset 0 0 2px #ff7474;
}
.field.input-error textarea {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
}
.field.input-error textarea:hover {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
  box-shadow: inset 0 0 2px #ff7474;
}
.field.input-error textarea:active,
.field.input-error textarea:focus {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
  box-shadow: inset 0 0 2px #ff7474;
}
.field.input-error .select-wrapper select {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
}
.field.input-error .select-wrapper select:hover {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
  box-shadow: inset 0 0 2px #ff7474;
}
.field.input-error .select-wrapper select:active,
.field.input-error .select-wrapper select:focus {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
  box-shadow: inset 0 0 2px #ff7474;
}
div.input-error {
  padding: 5px;
  border-width: 1px;
  border-radius: 3px;
  border-style: solid;
  box-sizing: border-box;
  color: #4a4a4a;
  background-color: #ffc1c1;
  border-color: #ff7474;
}
.spinner-holder {
  position: relative!important;
}
.spinner-holder .spinner {
  position: absolute;
}
.csstransforms .spinner-holder .spinner {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.no-csstransforms .spinner-holder .spinner {
  top: 50%;
  left: 50%;
  margin: 0;
  margin-top: -200px;
  margin-left: -250px;
}
.order-activity .tbl-div {
  font-size: 14px;
}
.order-activity .tbl-div .tbl-row .tbl-col.index {
  float: left;
  margin-right: 5px;
  font-weight: 700;
}
.order-activity .tbl-div .tbl-row .tbl-col.date {
  margin: 0 0 8px;
  font-weight: 700;
  text-align: right;
  border-bottom: 1px solid #bababa;
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  .order-activity .tbl-div .tbl-row .tbl-col.index {
    float: none;
    margin-right: 0;
    font-weight: 400;
  }
  .order-activity .tbl-div .tbl-row .tbl-col.date {
    margin: 0;
    font-weight: 400;
    text-align: left;
    border-bottom: 1px solid #868686;
  }
  .order-activity .tbl-div.data-tbl .tbl-row:hover .tbl-col.date {
    border-bottom-color: #57b1ff;
  }
}
.order-comments .tbl-div {
  font-size: 14px;
}
.order-comments .tbl-div .tbl-row .tbl-col.index {
  float: left;
  margin-right: 5px;
  font-weight: 700;
}
.order-comments .tbl-div .tbl-row .tbl-col.date {
  margin: 0 0 8px;
  font-weight: 700;
  text-align: right;
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  .order-comments .tbl-div .tbl-row .tbl-col.index {
    float: none;
    margin-right: 0;
    font-weight: 400;
  }
  .order-comments .tbl-div .tbl-row .tbl-col.date {
    margin: 0;
    font-weight: 400;
    text-align: left;
  }
}
.errors,
.success,
.warnings,
.error-msg,
.warning-msg,
.success-msg {
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
}
.errors p,
.success p,
.warnings p,
.error-msg p,
.warning-msg p,
.success-msg p {
  margin: 2px 0;
}
.errors .msg-icon,
.success .msg-icon,
.warnings .msg-icon,
.error-msg .msg-icon,
.warning-msg .msg-icon,
.success-msg .msg-icon {
  width: 20px;
  text-align: center;
  vertical-align: middle;
}
.errors .msg-info,
.success .msg-info,
.warnings .msg-info,
.error-msg .msg-info,
.warning-msg .msg-info,
.success-msg .msg-info {
  padding-left: 10px;
}
.errors,
.error-msg {
  color: #4a4a4a;
  background-color: #ffc1c1;
  border: 1px solid #ff7474;
}
.errors .msg-icon:before,
.error-msg .msg-icon:before {
  font-size: 20px;
  color: #8b1a1a;
  content: '\f06a';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
.warnings,
.warning-msg {
  color: #4a4a4a;
  background-color: #ffe7ba;
  border: 1px solid #ffcc6e;
}
.warnings .msg-icon:before,
.warning-msg .msg-icon:before {
  font-size: 20px;
  color: #ed9121;
  content: '\f071';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
.success,
.success-msg {
  color: #4a4a4a;
  background-color: #bdfcc9;
  border: 1px solid #74f98d;
}
.success .msg-icon:before,
.success-msg .msg-icon:before {
  font-size: 20px;
  color: #228b22;
  content: '\f058';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
.dashboard-widgets .widget-row {
  display: block;
  margin: 15px 0;
  border-top: 1px solid #c0c0c0;
}
.dashboard-widgets .widget-row:first-child {
  border-top: 0;
}
.dashboard-widgets .widget-row.widgets {
  display: flex;
  flex-flow: column;
  justify-content: space-between;
}
.dashboard-widgets .widget-row .widget {
  box-sizing: border-box;
  margin: 10px 0;
}
.dashboard-widgets .widget-row .widget h3 {
  margin: 5px 0 15px;
}
.dashboard-widgets .widget-row .widget .widget-content {
  padding: 15px 0;
}
.dashboard-widgets .widget-row .widget.mini {
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  box-sizing: border-box;
  display: block;
  overflow: hidden;
  width: 100%;
}
.dashboard-widgets .widget-row .widget.mini h3 {
  background-color: #222b3a;
  box-sizing: border-box;
  border-radius: 5px 5px 0 0;
  color: #ffffff;
  margin: 0;
  padding: 10px;
}
.dashboard-widgets .widget-row .widget.mini .widget-content {
  box-sizing: border-box;
  padding: 10px;
}
.dashboard-widgets .widget-row .widget.mini .needs-revision .status-title,
.dashboard-widgets .widget-row .widget.mini .awaiting-decision .status-title {
  border-bottom-width: 2px;
  border-bottom-style: solid;
}
.dashboard-widgets .widget-row .widget.mini .needs-revision .status-title .status-title-block,
.dashboard-widgets .widget-row .widget.mini .awaiting-decision .status-title .status-title-block {
  color: #ffffff;
  display: inline-block;
  font-weight: bold;
  padding: 5px;
}
.dashboard-widgets .widget-row .widget.mini .needs-revision .status-orders,
.dashboard-widgets .widget-row .widget.mini .awaiting-decision .status-orders {
  color: #3b3b3b;
  font-size: 0.7em;
  font-weight: normal;
  padding: 5px;
  max-height: 150px;
  overflow-x: hidden;
  overflow-y: auto;
}
.dashboard-widgets .widget-row .widget.mini .needs-revision .status-orders .order-link,
.dashboard-widgets .widget-row .widget.mini .awaiting-decision .status-orders .order-link {
  font-weight: bold;
  font-size: 14px;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.dashboard-widgets .widget-row .widget.mini .needs-revision .status-orders ul,
.dashboard-widgets .widget-row .widget.mini .awaiting-decision .status-orders ul {
  margin: 5px;
  padding-left: 10px;
}
.dashboard-widgets .widget-row .widget.mini .needs-revision .status-title {
  border-bottom-color: #ff0067;
}
.dashboard-widgets .widget-row .widget.mini .needs-revision .status-title .status-title-block {
  background-color: #ff0067;
}
.dashboard-widgets .widget-row .widget.mini .needs-revision ul,
.dashboard-widgets .widget-row .widget.mini .needs-revision a {
  color: #ff0067;
}
.dashboard-widgets .widget-row .widget.mini .awaiting-decision .status-title {
  border-bottom-color: #1e90ff;
}
.dashboard-widgets .widget-row .widget.mini .awaiting-decision .status-title .status-title-block {
  background-color: #1e90ff;
}
.dashboard-widgets .widget-row .widget.mini .awaiting-decision ul,
.dashboard-widgets .widget-row .widget.mini .awaiting-decision a {
  color: #1e90ff;
}
.dashboard-widgets .wfo_widget {
  overflow: hidden;
}
.dashboard-widgets .wfo_widget.wfo_number {
  display: inline-block;
  min-width: 90px;
  padding: 5px;
  width: auto;
}
.dashboard-widgets .wfo_widget.wfo_graph.pie {
  float: left;
  height: auto !important;
  max-width: 280px;
  width: 100% !important;
}
.dashboard-widgets .wfo_widget.wfo_graph.pie canvas {
  height: auto !important;
  max-width: 280px;
  width: 100% !important;
}
/**
 * small tablet
 */
@media only screen and (min-width: 769px) {
  .dashboard-widgets .widget-row.widgets {
    flex-flow: row nowrap;
  }
  .dashboard-widgets .widget-row .widget.mini {
    display: inline-block;
    vertical-align: top;
    width: 32%;
  }
}
body.login_page {
  background-color: #414141;
}
.login-wrapper {
  width: 90%;
  height: auto;
  margin: auto;
  padding: 20px;
  max-width: 500px;
  position: absolute;
  border-radius: 3px;
  background: #ffffff;
  box-sizing: border-box;
  box-shadow: 0 2px 8px #000000;
}
.csstransitions .login-wrapper {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.no-csstransitions .login-wrapper {
  top: 50%;
  left: 50%;
  margin: 0;
  margin-top: -200px;
  margin-left: -250px;
}
.login-wrapper.login-screen {
  margin: auto;
}
.breadcrumbs {
  margin: 15px 0 ;
  font-size: 13px;
}
footer {
  padding: 15px;
  color: #c0c0c0;
  font-size: 14px;
  min-height: 35px;
  text-shadow: 1px 1px 1px #000000;
}
header {
  z-index: 10;
  position: relative;
  background-color: #ffffff;
  box-shadow: 0 3px 3px #999999;
}
header .header-top {
  padding: 10px;
  display: block;
}
header .header-top .loggedin-user {
  text-align: center;
}
header .header-top .loggedin-user span {
  margin: 3px 0;
  display: block;
  max-width: 100px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
header .header-top .loggedin-user span:before {
  color: #4a4a4a;
  font-size: 18px;
  margin: 0 5px 5px 0;
  content: '\f2be';
  font-family: 'FontAwesome';
  margin-right: 5px;
}
header .header-top .loggedin-user .topmenu {
  cursor: default;
  padding: 7px 15px;
  position: relative;
  border-radius: 5px 5px 0 0;
  border: 1px solid transparent;
}
header .header-top .loggedin-user .topmenu ul.submenu {
  top: 100%;
  margin: 0;
  padding: 0;
  z-index: 1;
  right: -1px;
  width: 200px;
  display: none;
  overflow: hidden;
  list-style: none;
  position: absolute;
  background-color: #ffffff;
  border-radius: 5px 0 5px 5px;
  border: 1px solid #dcdcdc;
  box-shadow: -1px 3px 3px #bababa;
}
header .header-top .loggedin-user .topmenu ul.submenu li {
  margin: 0;
}
header .header-top .loggedin-user .topmenu ul.submenu li a {
  display: block;
  padding: 12px 10px;
  box-sizing: border-box;
  border-top: 1px solid #dcdcdc;
}
header .header-top .loggedin-user .topmenu ul.submenu li a:hover,
header .header-top .loggedin-user .topmenu ul.submenu li a:focus {
  color: #ffffff;
  background-color: #1e90ff;
}
header .header-top .loggedin-user .topmenu ul.submenu li:first-child a {
  border-top: none;
}
header .header-top .loggedin-user .topmenu:hover,
header .header-top .loggedin-user .topmenu:focus {
  border-color: #dcdcdc;
  background-color: #f5f5f5;
}
header .header-top .loggedin-user .topmenu:hover .submenu,
header .header-top .loggedin-user .topmenu:focus .submenu {
  display: block;
}
.app-display header {
  height: 80px;
  box-sizing: border-box;
  padding: 10px 0;
  box-shadow: 0 0 15px #dcdcdc;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
}
/**
 * small tablet
 */
@media only screen and (min-width: 601px) {
  header .header-top .loggedin-user span {
    display: inline;
    margin: 3px 0;
  }
  header .header-top .loggedin-user span:before {
    font-size: 25px;
  }
}
nav.mainnav {
  overflow: auto;
  font-size: 13px;
  margin: 10px 0 0;
  background-color: #f9f9f9;
  border-top: 1px solid #f4f4f4;
}
nav.mainnav a {
  float: left;
  color: #4a4a4a;
  overflow: hidden;
  position: relative;
  padding: 10px 12px;
  text-decoration: none;
  transition: all 0.25s linear;
}
nav.mainnav a:after {
  bottom: 0;
  content: '';
  left: 0;
  height: 4px;
  width: 100%;
  display: block;
  position: absolute;
  background-color: #1e90ff;
  transform: translateX(-100%);
  transition: all 0.5s ease-in-out;
}
nav.mainnav a:hover:after,
nav.mainnav a:focus:after,
nav.mainnav a.current:after {
  transform: translateX(0%);
}
.app-display .sidebar nav.mainnav {
  background-color: transparent;
  box-sizing: border-box;
  border: none;
  height: calc(100% - 50px);
  font-size: 16px;
  margin: 0;
  padding: 10px 0;
  overflow-x: hidden;
  overflow-y: auto;
}
.app-display .sidebar nav.mainnav a {
  color: #ffffff;
  display: block;
  float: none;
  overflow: hidden;
  padding: 18px 20px;
  white-space: nowrap;
}
.app-display .sidebar nav.mainnav a span {
  white-space: nowrap;
  text-overflow: clip;
}
.app-display .sidebar nav.mainnav a.dashboard:before {
  content: '\f0e4';
  font-family: 'FontAwesome';
  margin-right: 15px;
  font-size: 20px;
}
.app-display .sidebar nav.mainnav a.order:before {
  content: '\f15b';
  font-family: 'FontAwesome';
  margin-right: 15px;
  font-size: 20px;
}
.app-display .sidebar nav.mainnav a.user:before {
  content: '\f007';
  font-family: 'FontAwesome';
  margin-right: 15px;
  font-size: 20px;
}
.app-display .sidebar nav.mainnav a.admin:before {
  content: '\f023';
  font-family: 'FontAwesome';
  margin-right: 15px;
  font-size: 20px;
}
.app-display .sidebar nav.mainnav a:after {
  height: 100%;
  width: 4px;
  transform: translateY(-100%);
}
.app-display .sidebar nav.mainnav a:hover,
.app-display .sidebar nav.mainnav a:focus,
.app-display .sidebar nav.mainnav a.current {
  background-color: #171d27;
}
.app-display .sidebar nav.mainnav a:hover:after,
.app-display .sidebar nav.mainnav a:focus:after,
.app-display .sidebar nav.mainnav a.current:after {
  transform: translateY(0%);
}
.app-display .sidebar.collapsed nav.mainnav a span {
  opacity: 0;
}
/**
 * small tablet
 */
@media only screen and (min-width: 601px) {
  nav.mainnav {
    font-size: 16px;
  }
  nav.mainnav a {
    padding: 14px 20px;
  }
  .app-display nav.mainnav a {
    padding: 18px 20px;
  }
}
.order-list .tbl-row {
  overflow: hidden;
}
.order-list .tbl-row .tbl-col {
  font-size: 14px;
}
.order-list .tbl-row .tbl-col.preview-img {
  float: left;
  margin: 0 10px 10px 0;
}
.order-list .tbl-row .tbl-col.approved {
  color: #1da380;
}
.order-list .tbl-row .tbl-col.approved::before {
  content: '\f058';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
.order-list .tbl-row .tbl-col.rejected,
.order-list .tbl-row .tbl-col.revision {
  color: #cc0052;
}
.order-list .tbl-row .tbl-col.rejected::before,
.order-list .tbl-row .tbl-col.revision::before {
  content: '\f071';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
@media only screen and (min-width: 769px) {
  .order-list .tbl-row .tbl-col {
    font-size: 16px;
  }
  .order-list .tbl-row .tbl-col.preview-img {
    float: none;
    margin: 0;
  }
}
.version-bar {
  z-index: 2;
  display: block;
  bottom: -1px;
  position: relative;
}
.version-bar ul {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: hidden;
}
.version-bar ul li {
  display: inline-block;
}
.version-bar ul li a {
  color: #4a4a4a;
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  border-radius: 4px 4px 0 0;
  background-color: #c3cad2;
  border: 1px solid #8897a6;
  border-bottom-color: #8897a6;
}
.version-bar ul li a.current {
  border-bottom-color: #e0e4e8;
  background-color: #e0e4e8;
}
.order-info-wrapper {
  padding: 8px;
  margin: 0 0 15px;
  overflow: hidden;
  background-color: #e0e4e8;
  border: 1px solid #8897a6;
}
.order-info-wrapper .image-wrapper {
  width: 200px;
  margin: 10px;
  display: block;
  text-align: center;
}
.order-info-wrapper .image-wrapper img {
  background-color: #ffffff;
  border: 1px solid #c0c0c0;
}
.order-info-wrapper .image-wrapper .image-filename {
  color: #4a4a4a;
  font-size: 12px;
  text-align: left;
  word-break: break-all;
}
.order-info-wrapper .order-info h3 {
  color: #4a4a4a;
  margin: 25px 0 0;
  padding: 0 0 5px;
}
.order-info-wrapper .order-info hr {
  border: none;
  padding: 10px 0 0;
  margin: 10px 0 25px;
  border-bottom: 1px solid #999999;
}
.order-info-wrapper .order-info .order-info-item {
  float: left;
  width: auto;
  margin: 5px 0;
  min-width: 180px;
}
.order-info-wrapper .order-info .order-info-item span {
  margin: 0 2px;
  display: block;
  color: #4a4a4a;
}
.order-info-wrapper .order-info .order-info-item span:first-child {
  font-weight: bold;
}
.order-info-wrapper .order-info .order-info-item span.approved {
  color: #1da380;
}
.order-info-wrapper .order-info .order-info-item span.approved::before {
  content: '\f058';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
.order-info-wrapper .order-info .order-info-item span.rejected,
.order-info-wrapper .order-info .order-info-item span.revision {
  color: #cc0052;
}
.order-info-wrapper .order-info .order-info-item span.rejected::before,
.order-info-wrapper .order-info .order-info-item span.revision::before {
  content: '\f071';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
.order-users-wrapper {
  padding: 10px 0;
}
.order-users-wrapper h3 {
  margin-bottom: 5px;
}
.order-users-wrapper .saved-order-users-list,
.order-users-wrapper .order-users-list {
  margin: 0;
  padding: 0 20px;
  list-style: none;
}
.order-users-wrapper .saved-order-users-list li,
.order-users-wrapper .order-users-list li {
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid #d3d3d3;
}
.order-users-wrapper .saved-order-users-list li:first-child,
.order-users-wrapper .order-users-list li:first-child {
  margin-top: 0;
  padding-top: 0;
  border-top: none;
}
.order-users-wrapper .saved-order-users-list li .inline-buttons,
.order-users-wrapper .order-users-list li .inline-buttons {
  vertical-align: middle;
}
.order-users-wrapper .saved-order-users-list .user-status .approved,
.order-users-wrapper .order-users-list .user-status .approved {
  color: #25cea2;
}
.order-users-wrapper .saved-order-users-list .user-status .rejected,
.order-users-wrapper .order-users-list .user-status .rejected {
  color: #ff0067;
}
.order-users-wrapper .saved-order-users-list .remove-user-record,
.order-users-wrapper .order-users-list .remove-user-record,
.order-users-wrapper .saved-order-users-list .delete-user-record,
.order-users-wrapper .order-users-list .delete-user-record {
  display: block;
  font-size: 30px;
  overflow: hidden;
  color: #b22222;
  text-decoration: none;
}
.order-users-wrapper .saved-order-users-list .remove-user-record:hover,
.order-users-wrapper .order-users-list .remove-user-record:hover,
.order-users-wrapper .saved-order-users-list .delete-user-record:hover,
.order-users-wrapper .order-users-list .delete-user-record:hover,
.order-users-wrapper .saved-order-users-list .remove-user-record:focus,
.order-users-wrapper .order-users-list .remove-user-record:focus,
.order-users-wrapper .saved-order-users-list .delete-user-record:focus,
.order-users-wrapper .order-users-list .delete-user-record:focus {
  color: #d73030;
}
.order-users-wrapper .saved-order-users-list .remove-user-record:active,
.order-users-wrapper .order-users-list .remove-user-record:active,
.order-users-wrapper .saved-order-users-list .delete-user-record:active,
.order-users-wrapper .order-users-list .delete-user-record:active {
  color: #871a1a;
}
.order-users-wrapper .saved-order-users-list .remove-user-record span,
.order-users-wrapper .order-users-list .remove-user-record span,
.order-users-wrapper .saved-order-users-list .delete-user-record span,
.order-users-wrapper .order-users-list .delete-user-record span {
  width: 0;
  height: 0;
  display: inline-block;
  text-indent: -10000px;
}
.order-users-wrapper .saved-order-users-list li:first-child .field {
  margin-top: 0;
}
.order-users-wrapper .order-users-list > li:first-child {
  margin-top: 40px;
  padding-top: 25px;
  border-top: 1px solid #d3d3d3;
}
.order-users-wrapper .button-holder {
  text-align: right;
  padding: 20px 20px 0;
  box-sizing: border-box;
}
.no-js .order-users-wrapper .button-holder button {
  display: none;
}
/**
 * small tablet
 */
@media only screen and (min-width: 601px) {
  .order-info-wrapper {
    position: relative;
  }
  .order-info-wrapper .image-wrapper {
    float: left;
  }
  .order-info-wrapper .order-info {
    float: left;
    width: 100%;
    display: block;
    padding: 0 15px;
    padding-right: 250px;
    margin-right: -250px;
    margin-bottom: 15px;
    box-sizing: border-box;
  }
  .order-info-wrapper .order-info h3 {
    margin: 10px 0 0;
    padding: 0 0 5px;
  }
  .order-info-wrapper .order-info .order-info-item {
    margin: 0;
    width: 30%;
    float: left;
    padding: 5px;
    min-width: 180px;
    box-sizing: border-box;
  }
}
/**
 * tablet
 */
@media only screen and (min-width: 769px) {
  .order-users-wrapper .saved-order-users-list,
  .order-users-wrapper .order-users-list {
    margin: 0;
    padding: 0 20px;
    list-style: none;
  }
  .order-users-wrapper .saved-order-users-list li,
  .order-users-wrapper .order-users-list li {
    margin-top: 0;
    padding-top: 0;
    border-top: none;
  }
}
/**
 * small desktop
 */
@media only screen and (min-width: 961px) {
  .version-bar ul li a.current:hover,
  .version-bar ul li a.current:focus {
    cursor: default;
    background-color: #e0e4e8;
  }
  .version-bar ul li a:hover,
  .version-bar ul li a:focus {
    background-color: #e6e9ec;
  }
}
/**
 * Admin Colors
 */
body {
  background-color: #35435a;
}
h1 {
  margin-bottom: 15px;
  padding-bottom: 15px;
  border-bottom: 1px solid #d4d4d4;
}
.wrapper {
  height: auto;
  min-height: 400px;
  position: relative;
  box-sizing: border-box;
  padding: 10px 25px 15px;
  background-color: #f5f5f5;
}
.app-display {
  box-sizing: border-box;
  height: 100vh;
  overflow: hidden;
  width: 100%;
  min-width: 600px;
}
.app-display .sidebar {
  background-color: #222b3a;
  box-sizing: border-box;
  color: #ffffff;
  height: calc(100% - 80px);
  left: 0;
  margin: 0;
  padding: 20px 0;
  position: absolute;
  width: 180px;
  transition: 0.3s width ease-in-out;
  z-index: 10;
}
.app-display .sidebar .collapse-btn-wrapper {
  border-top: 1px solid #0f131a;
}
.app-display .sidebar .collapse-btn-wrapper .collapse-btn {
  float: none;
  clear: both;
  display: block;
  padding: 18px 20px;
}
.app-display .sidebar .collapse-btn-wrapper .collapse-btn span {
  border-radius: 100%;
  background-color: #222b3a;
  color: #ffffff;
  cursor: pointer;
  float: right;
  height: 15px;
  padding: 10px;
  transition: 0.25s background-color linear, 0.25s color linear;
  width: 15px;
}
.app-display .sidebar .collapse-btn-wrapper .collapse-btn span:before {
  content: '\f053';
  font-family: 'FontAwesome';
  margin-right: 2px;
}
.app-display .sidebar .collapse-btn-wrapper .collapse-btn span:hover {
  background-color: #ffffff;
  color: #222b3a;
}
.app-display .sidebar.collapsed {
  width: 60px;
}
.app-display .sidebar.collapsed + .main-section {
  padding-left: 60px;
}
.app-display .sidebar.collapsed .collapse-btn span:before {
  content: '\f054';
  font-family: 'FontAwesome';
  margin-left: 3px;
}
.app-display .main-section {
  box-sizing: border-box;
  overflow: auto;
  padding-left: 180px;
  width: 100%;
  height: calc(100% - 80px);
  transition: 0.3s padding ease-in-out;
}
