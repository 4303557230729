body.login_page {
	background-color: darken(@silver, 50%);
}

.login-wrapper {
	width: 90%;
	height: auto;
	margin: auto;
	padding: 20px;
	max-width: 500px;
	position: absolute;
	border-radius: 3px;
	background: @white;
	box-sizing: border-box;
	box-shadow: 0 2px 8px @black;

	.csstransitions & {
		.center(true, true);
	}

	.no-csstransitions & {
		.center-notransform(true, true);
	}

	&.login-screen {
		margin: auto;
	}
}
