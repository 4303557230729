@info-wrapper-color: lighten(@lightslategray, 36%);

.version-bar {
	z-index: 2;
	display: block;
	bottom: -1px; // overlap info-wrapper by 1 px;
	position: relative;
	ul {
		margin: 0;
		padding: 0;
		list-style: none;
		overflow: hidden;
		li {
			display: inline-block;
			a {
				color: @gray29;
				display: block;
				padding: 5px 10px;
				text-decoration: none;
				border-radius: 4px 4px 0 0;
				background-color: darken(@info-wrapper-color, 10%);
				border: 1px solid darken(@info-wrapper-color, 30%);
				border-bottom-color: darken(@info-wrapper-color, 30%);
				&.current {
					border-bottom-color: @info-wrapper-color;
					background-color: @info-wrapper-color;
				}
			}
		}
	}
}

.order-info-wrapper {
	padding: 8px;
	margin: 0 0 15px;
	overflow: hidden;
	background-color: @info-wrapper-color;
	border: 1px solid darken(@info-wrapper-color, 30%);
	.image-wrapper {
		width: 200px;
		margin: 10px;
		display: block;
		text-align: center;
		img {
			background-color: @white;
			border: 1px solid @silver;
		}
		.image-filename {
			color: @gray29;
			font-size: 12px;
			text-align: left;
			word-break: break-all;
		}
	}

	.order-info {
		h3 {
			color: @gray29;
			margin: 25px 0 0;
			padding: 0 0 5px;
		}
		hr {
			border: none;
			padding: 10px 0 0;
			margin: 10px 0 25px;
			border-bottom: 1px solid @gray60;
		}
		.order-info-item {
			float: left;
			width: auto;
			margin: 5px 0;
			min-width: 180px;
			span {
				margin: 0 2px;
				display: block;
				color: @gray29;
				&:first-child {
					font-weight: bold;
				}
				&.approved {
					color: darken(@frontend_approvegreen, 10%);
					&::before {
						.font-icon('\f058');
					}
				}
				&.rejected,
				&.revision {
					color: darken(@frontend_rejectionred, 10%);
					&::before {
						.font-icon('\f071');
					}
				}
			}
		}
	}
}

.order-users-wrapper {
	padding: 10px 0;
	h3 {
		margin-bottom: 5px;
	}
	.saved-order-users-list, .order-users-list {
		margin: 0;
		padding: 0 20px;
		list-style: none;
		& li {
			margin-top: 10px;
			padding-top: 10px;
			border-top: 1px solid @lightgray;
			&:first-child {
				margin-top: 0;
				padding-top: 0;
				border-top: none;
			}
			.inline-buttons {
				vertical-align: middle;
			}
		}
		.user-status {
			.approved {
				color: @frontend_approvegreen;
			}
			.rejected {
				color: @frontend_rejectionred;
			}
		}
		.remove-user-record, .delete-user-record {
			display: block;
			font-size: 30px;
			overflow: hidden;
			color: @firebrick;
			text-decoration: none;
			&:hover, &:focus {
				color: lighten(@firebrick, 10%);
			}
			&:active {
				color: darken(@firebrick, 10%);
			}
			span {
				width: 0;
				height: 0;
				display: inline-block;
				text-indent: -10000px;
			}
		}
	}
	.saved-order-users-list {
		& li:first-child {
			.field {
				margin-top: 0;
			}
		}
	}
	.order-users-list {
		& > li:first-child {
			margin-top: 40px;
			padding-top: 25px;
			border-top: 1px solid @lightgray;
		}
	}
	.button-holder {
		text-align: right;
		padding: 20px 20px 0;
		box-sizing: border-box;
		// hide buttons when js is not enabled
		.no-js & {
			button {
				display: none;
			}
		}
	}
}

/**
 * small tablet
 */
@media only screen and (min-width : @sm-tablet-width) {
	.order-info-wrapper {
		position: relative;
		.image-wrapper {
			float: left;
		}
		.order-info {
			float: left;
			width: 100%;
			display: block;
			padding: 0 15px;
			padding-right: 250px;
			margin-right: -250px;
			margin-bottom: 15px;
			box-sizing: border-box;
			h3 {
				margin: 10px 0 0;
				padding: 0 0 5px;
			}
			.order-info-item {
				margin: 0;
				width: 30%;
				float: left;
				padding: 5px;
				min-width: 180px;
				box-sizing: border-box;
			}
		}
	}
}

/**
 * tablet
 */
@media only screen and (min-width : @tablet-width) {
	.order-users-wrapper {
		.saved-order-users-list, .order-users-list {
			margin: 0;
			padding: 0 20px;
			list-style: none;
			& li {
				margin-top: 0;
				padding-top: 0;
				border-top: none;
			}
		}
	}
}

/**
 * small desktop
 */
@media only screen and (min-width : @sm-desktop-width) {
	.version-bar {
		ul {
			li {
				a {
					&.current {
						&:hover, &:focus {
							cursor: default;
							background-color: @info-wrapper-color;
						}
					}
					&:hover, &:focus {
						background-color: lighten(@info-wrapper-color, 2%);
					}
				}
			}
		}
	}
}
